export function interpretAddress(data) {
    const {line1, full_name, line2, line3, town_name, postcode} = data;
    let fullNameSplit =  full_name ? full_name.split(',') : [];
    let address = [ ...fullNameSplit,  line1  , line2, line3, town_name].filter(Boolean).map(function (el) { return el.trim();});
    address = removeDuplicates(address);
    let name = address[0];
    address.shift();
    const fixedAddress = [...address , postcode].filter(Boolean).join(", ")
    return [name,fixedAddress];
}

function removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}